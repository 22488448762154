import { Offer } from "../Offer";

export const OfferContent = () => {
  return (
    <>
      <div className="modal-header">
        <img src="/images/title.svg" alt="" className="title" />
      </div>
      <div className="modal-content">
        <Offer>
          <div className="container">
            <img src="/images/bet7k-white-icon.svg" alt="" />
            <span>20 Rodadas grátis </span>| Deposite R$10
          </div>
        </Offer>
        <Offer>
          <div className="container">
            <img src="/images/bet7k-white-icon.svg" alt="" />
            <span>40 Rodadas grátis </span>| Deposite R$20
          </div>
        </Offer>
        <Offer>
          <div className="container">
            <img src="/images/bet7k-white-icon.svg" alt="" />
            <span>100 Rodadas grátis </span>| Deposite R$50
          </div>
        </Offer>

        <div className="btn">
          <Offer className="btn">finalizar cadastro</Offer>
        </div>
      </div>

      <div>
        <img
          id="logo"
          src="/images/logo-bet7k.svg"
          alt=""
          className="max-w-[96px]"
        />
      </div>
    </>
  );
}; //
