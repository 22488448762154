import styled from "styled-components";

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const ButtonContainer = styled.button`
  border: 2px solid #d6ed16;
  background: linear-gradient(90deg, #eefa83 0%, #d6ed16 56.05%, #9eae16 100%);
  font-family: "Gotham-Black";
  color: #1f2600;
  cursor: pointer;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    scale: 1.1;
    filter: brightness(1.1);
  }

  &:disabled {
    background: linear-gradient(to right, #766253, #5f534b, #332b25);
    color: #a1a1a1;
    border: 2px solid #54493e;
  }
`;

export const Button = ({ ...props }: ButtonProps) => {
  return <ButtonContainer {...props} />;
};
