import { useApp } from "../../hooks/useApp";
import { Button } from "../Button";

import styled from "styled-components";

const DiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  min-height: 550px;
  width: 100%;
  max-width: 498px;

  @media (max-width: 430px) {
    justify-content: start;
    min-height: fit-content;
  }

  @media (max-width: 390px) {
  }

  @media (max-width: 361px) {
  }

  .headline {
    width: 70%;

    @media (max-width: 430px) {
      margin-top: 0rem;
      margin-bottom: 0.75rem;
    }

    @media (max-width: 390px) {
    }

    @media (max-width: 361px) {
    }
  }

  .dice-body {
    background-image: url("/images/bg-dice-game.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    height: 550px;

    @media (max-width: 1920px) {
      width: 100%;
    }

    @media (max-width: 430px) {
      height: 350px;
      margin-bottom: 1rem;
    }

    @media (max-width: 390px) {
    }

    .dragon-tiger-mobile {
      display: flex;

      @media (max-width: 768px) {
        display: flex;
      }

      @media (max-width: 425px) {
        margin-bottom: 1.25rem;
        width: 65%;
      }

      @media (max-width: 375px) {
        margin-bottom: 3rem;
      }

      @media (max-width: 320px) {
        margin-bottom: 6rem;
      }
    }

    .body-content {
      .dice-table {
        min-width: 100%;
      }

      @media (max-width: 425px) {
        .counter {
          font-size: 0.75rem;
        }
      }

      @media (max-width: 440px) {
        .dice-table {
          max-width: 250px;
          width: 250px;
          min-width: 250px;
        }
      }

      @media (max-width: 391px) {
        .dice-table {
          width: 95%;
        }
      }

      @media (max-width: 376px) {
        .dice-table {
          max-width: 250px;
          width: 250px;
          min-width: 250px;
        }
      }

      @media (max-width: 361px) {
        .dice-table {
          width: 70%;
        }
      }
    }
  }

  .play-btn {
    @media (max-width: 430px) {
      margin-top: 0rem;
      margin-bottom: 0rem;
      font-size: 1rem;
    }

    @media (max-width: 390px) {
      font-size: 0.75rem;
    }

    @media (max-width: 361px) {
    }
  }
`;

export const DiceGame = () => {
  const { rollDice, diceBoxRef, diceCanvasRef, loading, round } = useApp();

  return (
    <DiceContainer>
      <img src="/images/headline.svg" alt="" className="headline mt-7 mb-8" />
      <div className="dice-body flex flex-col w-full mb-8">
        <img
          className="dragon-tiger-mobile mb-16"
          src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/ad6a9988-5633-4a54-a115-889a204b7000/public"
        />

        <div className="body-content flex justify-center items-center  max-w-[360px] w-full absolute bottom-0">
          <img
            className="dice-table"
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/c66ea548-3158-4ce1-4cc5-1653fbceaf00/public"
          />

          <div
            id="dice-box"
            ref={diceBoxRef}
            className="flex justify-center items-center absolute z-[98] w-full max-w-[85%] min-h-[85%] rounded-[15%]"
          >
            <div
              className="counter absolute z-[99] top-[-35px] flex gap-2 rounded-lg px-4 py-1"
              style={{
                background:
                  "linear-gradient(90deg, #eefa83 0%, #d6ed16 56.05%, #9eae16 100%)",
                fontFamily: "Gotham-Black",
                border: "2px solid #d6ed16",
              }}
            >
              <p className="text-[#1f2600]">Tentativas: </p>{" "}
              <strong className="text-[#1f2600]">{2 - round}</strong>
            </div>
            <canvas
              id="dice-canvas"
              ref={diceCanvasRef}
              className="bg-blue-500 absolute z-[99] w-0 h-0 rounded-[15%]"
            ></canvas>
          </div>
        </div>
      </div>

      <Button
        className="play-btn py-2 px-10 gap-2 text-lg uppercase mb-4"
        disabled={loading}
        onClick={loading ? () => {} : rollDice}
      >
        Jogue os dados
        {loading ? (
          <img
            className="animate-spin w-6 h-6"
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/f0de6a09-85e6-414d-b06d-1d8960073e00/public"
          />
        ) : (
          <img className="w-6" src="/images/bet7k-green-icon.svg" />
        )}
      </Button>
    </DiceContainer>
  );
};
//
